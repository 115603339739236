exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-contact-template-js": () => import("./../../../src/templates/Contact.template.js" /* webpackChunkName: "component---src-templates-contact-template-js" */),
  "component---src-templates-home-template-js": () => import("./../../../src/templates/Home.template.js" /* webpackChunkName: "component---src-templates-home-template-js" */),
  "component---src-templates-insights-resources-template-js": () => import("./../../../src/templates/InsightsResources.template.js" /* webpackChunkName: "component---src-templates-insights-resources-template-js" */),
  "component---src-templates-resource-template-js": () => import("./../../../src/templates/Resource.template.js" /* webpackChunkName: "component---src-templates-resource-template-js" */),
  "component---src-templates-technology-template-js": () => import("./../../../src/templates/Technology.template.js" /* webpackChunkName: "component---src-templates-technology-template-js" */),
  "component---src-templates-vinex-template-js": () => import("./../../../src/templates/Vinex.template.js" /* webpackChunkName: "component---src-templates-vinex-template-js" */)
}

